<template>
    <MasterDetail
      ref="masterDetail"
      :hasExportCSV="false"
      :hasExportXLSX="false"
      :opts="opts"
      :cols="cols"
      :canEdit="false"
      :canDelete="false"
      :hasNewButton="false"
      :actionBarButtons="actionBarButtons"
      :contextOptions="contextMenu"
      :customResource="customResource">

      <FormModal
        title="Cadastro de tipos de rubricas"
        :opened.sync="formModalOpen"
        :value.sync="formModalValue"
        :cols="cols"
        :opts="opts"
        @save="saveRubrica()">
      </FormModal>

      <FormModal
        title="Edição de tipos de rubricas"
        :opened.sync="formModalOpenEdit"
        :value.sync="formModalValueEdit"
        :cols="colsEdit"
        :opts="opts"
        @save="editRubrica()">
      </FormModal>

    </MasterDetail>
</template>

<script>
import MasterDetail from '@/components/master-detail.vue';
import FormModal from '@/components/form-modal.vue';

export default {
    // props: {},
    components: {
        MasterDetail,
        FormModal,
    },
    // mixins: [],
    // directives: {},
     data() {
         return {
           opts: {
             statusRubrica: [
               {
                 id: 1,
                 value: 'Ativo'
               },
               {
                 id: 0,
                 value: 'Inativo'
               },
             ],
            },
            formModalOpen: false,
            formModalValue: {},
            formModalOpenEdit: false,
            formModalValueEdit: {},
         }
     },
    computed: {
      actionBarButtons() {
        const actionBarButtons = [];
        const that = this;
        actionBarButtons.push({
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          show: true,
          action(){
            that.formModalValue = {};
            that.formModalOpen = true;
          }
        });

        return actionBarButtons.filter(({ show }) => show);
      },
      contextMenu() {
        const contextOptions = [];
        const that = this;

        contextOptions.push(
          {
            show: this.userHasAccess("Captacao.tipoRubricas"),
            name: "Editar",
            cb: (row) => {
              that.formModalValueEdit = row;
              that.formModalOpenEdit = true;
            },
          },
          {
            show: this.userHasAccess("Captacao.tipoRubricas"),
            name: "Excluir",
            cb: (row) => {
              that.deleteRubrica(row);
            },
          },
        );

        return contextOptions.filter(({ show }) => show);
      },
      cols() {
        return [
          {
            key: 'id',
            name: 'Código',
            hideInform: true,
            width: '50px',
          },
          {
            key: 'tipo',
            name: 'Tipo rubrica',
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: 'required' }],
          },
          {
            key: 'ativo',
            name: 'Status',
            type: this.$fieldTypes.SELECT,
            rel: { to:'statusRubrica', key: 'id', name: 'value'},
            hideInform: true,
          },
        ]
      },
      colsEdit() {
        return [
          {
            key: 'tipo',
            name: 'Tipo rubrica',
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: 'required' }],
          },
          {
            key: 'ativo',
            name: 'Status',
            type: this.$fieldTypes.SELECT,
            rel: { to:'statusRubrica', key: 'id', name: 'value'},
          },
        ]
      },
      customResource() {
        const resource = this.apiResource('/v1/captacao/tipoRubricas');
        return {
          ...resource,
          get(...params) {
            return resource.get(params).then((result) => {
              return result;
            })
          }
        }
      },
      resourceUrl() {
        return this.apiResource('/v1/captacao/tipoRubricas');
      },
    },
    // filters: {},
    // created() {},
    // mounted() {},
    // updated() {},
    // destroyed() {},
     methods: {
      saveRubrica() {
        const resource = this.resourceUrl;
        const formData = {...this.formModalValue, ativo: 1}
        return resource.save(formData).then((result) => {
          this.formModalOpen = false;
          this.formModalData = {};
          this.$refs.masterDetail.doLoad();
          return result;
        });
      },
      editRubrica() {
        const resource = this.resourceUrl;
        const formData = { ...this.formModalValueEdit }
        return resource.save(formData, this.formModalValueEdit.id).then((result) => {
          this.formModalOpenEdit = false;
          this.formModalValueEdit = {};
          this.$refs.masterDetail.doLoad();
          return result;
        });
      },
      deleteRubrica(dados = {}) {
        const resource = this.resourceUrl;
        return resource.delete(dados.id).then((result) => {
          this.$refs.masterDetail.doLoad();
          return result;
        });
      }
     },
    // watch: {},
}
</script>

<style>

</style>
